import { Injectable } from '@angular/core';
import {
  of,
  from,
  Observable,
  throwError,
  shareReplay,
  catchError,
  concatMap,
  map,
  combineLatest,
  first,
  EMPTY
} from 'rxjs';
import { Auth0Client, createAuth0Client, GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import { environment } from 'src/environments/environment';
import { Auth0LoginErrorEnum } from '../models/authentication.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  auth0Client$: Observable<Auth0Client> = from(
    createAuth0Client({
      domain: environment.auth0.domain,
      clientId: environment.auth0.client_id,
      authorizationParams: {
        redirectUri: environment.auth0.redirect_uri,
        audience: environment.auth0.audience
      },
      useRefreshTokens: true
    })
  ).pipe(
    shareReplay(1),
    catchError(err => throwError(() => new Error(err)))
  );
  isAuthenticated$ = this.auth0Client$.pipe(concatMap((client: Auth0Client) => from(client.isAuthenticated())));
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );

  getTokenSilently$(): Observable<GetTokenSilentlyVerboseResponse> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) =>
        from(client.getTokenSilently({ detailedResponse: true })).pipe(
          catchError(e => {
            if (e.error === 'missing_refresh_token' || e.error === 'invalid_grant') {
              client.loginWithRedirect({
                authorizationParams: {
                  redirect_uri: environment.auth0.redirect_uri
                },
                appState: { target: location.pathname }
              });
              return EMPTY;
            } else {
              return throwError(() => e);
            }
          })
        )
      )
    );
  }

  login(redirectPath?: string): Observable<void> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) =>
        client.loginWithRedirect({
          authorizationParams: {
            redirect_uri: environment.auth0.redirect_uri
          },
          appState: { target: redirectPath || location.pathname }
        })
      )
    );
  }

  handleAuthCallback(): Observable<{ loggedIn: boolean; targetUrl: string; errorType?: Auth0LoginErrorEnum }> {
    return combineLatest({
      params: of(new URL(document.location.href).searchParams),
      redirectResponse: this.handleRedirectCallback$.pipe(
        concatMap(result => this.isAuthenticated$.pipe(map(loggedIn => ({ appState: result.appState, loggedIn }))))
      )
    }).pipe(
      concatMap(({ params, redirectResponse }) => {
        if (params.get('error')) {
          return of({ loggedIn: redirectResponse.loggedIn, targetUrl: '', errorType: Auth0LoginErrorEnum.unknown });
        } else if (params.get('code') && params.get('state')) {
          return redirectResponse.loggedIn
            ? of({
                loggedIn: redirectResponse.loggedIn,
                targetUrl: redirectResponse.appState.target
              })
            : of({ loggedIn: redirectResponse.loggedIn, targetUrl: '/callback' });
        } else {
          return of({ loggedIn: redirectResponse.loggedIn, targetUrl: redirectResponse.appState.target });
        }
      })
    );
  }

  logout() {
    this.auth0Client$.pipe(first()).subscribe((client: Auth0Client) => {
      client.logout({
        clientId: environment.auth0.client_id,
        logoutParams: {
          returnTo: environment.auth0.logout_url
        }
      });
    });
  }
}
