// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  uat: false,
  qa: false,
  develop: true,
  baseURL: 'https://api.dev.symplirent.com',
  environment: 'develop',
  auth0: {
    client_id: 'sO0v1rfFZ8eh2BZ85irBspsyoonxutpD',
    domain: 'symplirent-dev.us.auth0.com',
    audience: 'https://symplirent-dev.us.auth0.com/api/v2/',
    logout_url: 'https://develop.app.symplirent.com/auth/login',
    redirect_uri: 'https://develop.app.symplirent.com/callback'
  }
};
